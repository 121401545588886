import "./styles/App.css";
import { Todo } from "./pages/Todo";

function App() {
  return (
    <div className="App">
      <Todo />
    </div>
  );
}

export default App;
